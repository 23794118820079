<template>
  <div class="mt-4  px-5">

    <h2>Schiessbuch</h2>

    <v-alert dense type="info">
      <b>Tipp:</b>
      <div class="">Hier werden alle beendeten Einträge von dir angezeigt. <br>
        Möchtest du von deinem Verein, ein Training bestätigt bekommen, so klicke auf <i
            class="fas fa-hands-helping"></i> "bestätigen". <br>
        <br><i class="fas fa-user-graduate"></i> Dein Schuetzenbuch kannst du exportieren, falls du es mal bei einem
        Amt vorlegen möchtest.
      </div>
    </v-alert>

    <OpenShootingBookEntry/>

    <v-data-table
        :headers="headers"
        :items="comp_myShootingbook"
        :sort-by="['created_at']"
        :sort-desc="[true]"
        class="elevation-1 mt-8"
        ref="myShootingbook"
        :item-class="itemRowClass"
        :loading="comp_Progress_loading"
        loader-height="5"
    >
      <template v-slot:top>
        <v-toolbar
            flat
            dense
            color="success"
        >
          <v-toolbar-title>Schiessbuch</v-toolbar-title>

          <v-spacer></v-spacer>
          <shootingbook-p-d-f-export
              :pdf_data="comp_myShootingbook"
              :name="comp_Name"
              club_user="user">
          </shootingbook-p-d-f-export>
          <shootingbook-excel-export
              :excel_data="comp_myShootingbook"
              :name="comp_Name"
              club_user="user">
          </shootingbook-excel-export>
          <v-btn icon
                 @click="getMyShootingbook">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

        </v-toolbar>
      </template>

      <template v-slot:item.beginn="{ item }">
        <span v-if="item.club_id !=null">{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
          }}</span>
        <span v-else>{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'})
          }}</span>
      </template>
      <template v-slot:item.ende="{ item }">
        <span v-if="item.club_id !=null">{{
            new Date(item.ende.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
          }}</span>
        <span v-else>{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'})
          }}</span>
      </template>

      <template v-slot:item.approval="{ item }">
        <v-btn
            v-show="item.bestaetigt_vereinDatum === null"
            v-if="item.bestaetigung_holen === 1 && item.club_id != null"
            class="btn-warning"
            color="warning"
            @click="send_to_confirmation(item)"
        >
          <v-icon
              class="mr-2"
              x-small>fas fa-thumbtack
          </v-icon>
          zurücknehmen

        </v-btn>

        <v-btn
            v-if="( item.bestaetigung_holen === false || item.bestaetigung_holen == null || item.bestaetigung_holen === 0) && item.club_id != null"
            class="btn-info"
            color="info"
            @click="send_to_confirmation(item)"
        >
          <v-icon
              class="mr-2"
              x-small
          >fas fa-hands-helping
          </v-icon>
          bestätigen

        </v-btn>

        <shootingbook-sign-entry
            v-show="item.bestaetigt_vereinDatum === null"
            v-if="check_bestaetigung_holen(item) === false && item.club_id === null"
            :item="item">
        </shootingbook-sign-entry>

        <shootingbook-show-signature
            v-show="item.bestaetigt_vereinDatum != null && item.club_id === null"
            :item="item"
        ></shootingbook-show-signature>


      </template>

      <template v-slot:item.approvaldate="{ item }">

            <span v-if="item.bestaetigt_vereinDatum !== null">
             <span>
               Freigegeben am: <br>
               {{
                 new Date(item.bestaetigt_vereinDatum.replace(" ", "T")).toLocaleString([], {
                   dateStyle: 'short',
                   timeStyle: 'short'
                 })
               }}
             </span>
            </span>

      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
            v-if="item.bestaetigt_vereinDatum === null && check_bestaetigung_holen(item) === false"
            class="error ml-2"
            @click="delShootingBookEntry_Dialog(item)"
        >
          <v-icon>
            mdi-trash-can
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          neu laden
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Willst du diesen Eintrag wirklich löschen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false; prepare_delete_item = {}">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="delShootingBooKEntry()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import shootingbookExcelExport from '@/views/component/shootingbookExcelExport';
import shootingbookPDFExport from '@/views/component/shootingbookPDFExport';
import shootingbookShowSignature from "@/views/component/shootingbookShowSignature.vue";
import shootingbookSignEntry from "@/views/component/shootingbookSignEntry.vue";
import OpenShootingBookEntry from "@/views/component/OpenShootingBookEntry.vue";

export default {
  name: "shootingbook",
  components: {
    shootingbookExcelExport, shootingbookPDFExport, shootingbookShowSignature, shootingbookSignEntry, OpenShootingBookEntry
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogSign: false,
    loading: false,
    headers: [
      {
        align: 'Vereinsnummer',
        sortable: false,
      },
      {text: 'Verein', value: 'comp_vereinname'},
      {text: 'Aktivität', value: 'aktivitaet'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Bahn', value: 'bahn'},
      {text: 'Standaufsicht', value: 'Aufsicht'},
      {text: 'Waffe - Kurztext', value: 'KurzT'},
      {text: 'Begin', value: 'beginn'},
      {text: 'Ende', value: 'ende'},
      {text: 'Disziplin', value: 'LangBez'},
      {text: 'Schuss', value: 'tr_schuss'},
      {text: 'Ergebniss', value: 'trergges'},
      // {text: 'Actions', value: 'actions', sortable: false},
      {text: 'Verein freigabe', value: 'approval', sortable: false},
      {text: 'Freigabe', value: 'approvaldate', sortable: false},
      {text: 'Action', value: 'action', sortable: false},
    ],

    editedItem: {},
    signature_style: {border: 'black 3px solid'},
    prepare_delete_item: {}
  }),

  computed: {
    comp_myShootingbook() {
      return this.$store.state.UserApp.MyShootingbook;
    },
    comp_Progress_loading() {
      return this.$store.getters['UserApp/is_loading_shootingbook'];
    },
    comp_Name() {
      return this.$store.getters['UserAuth/isUserName'];
    },

  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    getMyShootingbook() {
      this.$store.dispatch('UserApp/getMyShootingbook')
    },

    send_to_confirmation(item) {
      this.$store.commit('UserApp/SET_SHOOTINGBOOK_LOADER', true);

      var Data;
      Data = {
        freigabeID: null,
        freigabebool: null,
      };

      Data.freigabeID = item.id;

      if (item.bestaetigung_holen === 0 || item.bestaetigung_holen === null) {
        Data.freigabebool = true
      } else {
        Data.freigabebool = false
      }

      this.$store.dispatch('UserApp/send_ShootingBookRecord_to_confirmation', Data)
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    }
    ,
    itemRowClass(item) {
      var Class = null;
      if (item.bestaetigt_vereinDatum !== null) {
        Class = 'green lighten-4'
      } else if (item.bestaetigung_holen === 1) {
        Class = 'amber lighten-4'
      } else {
        Class = ''
      }
      return Class;
    },

    initialize() {
      this.$store.dispatch('UserApp/getMyShootingbook')
    },

    delShootingBookEntry_Dialog(item) {
      this.dialogDelete = true;
      this.prepare_delete_item = Object.assign({}, item)
    },

    delShootingBooKEntry() {
      const Data = {
        'shootingbook_id': this.prepare_delete_item.id
      }

      this.$store.dispatch('UserApp/delShootingbookEntry', Data)
          .then(this.dialogDelete = false,
              this.prepare_delete_item = {}
          )
    },
    check_bestaetigung_holen(item) {
      if (item.bestaetigung_holen === false || item.bestaetigung_holen == null || item.bestaetigung_holen === 0) {
        return false
      } else {
        return true
      }
    }

  },

}

</script>

<style scoped>


</style>

