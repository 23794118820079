<template>
  <v-btn color="indigo" dark="" class="mr-5 mb-2 mt-2" @click="makeShootingBookPDF()">
    PDF
    <v-icon right>mdi-file-pdf-outline</v-icon>
  </v-btn>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable';

export default {
  name: 'shootingbookPDFExport',
  props: {
    pdf_data: {
      type: Array
    },
    name: {
      type: String
    },
    club_user: {
      type: String
    },
  },
  data: () => ({}),
  methods: {
    makeShootingBookPDF() {
      var source = this.pdf_data;
      let rows = [];
      let columnHeader = [];
      if (this.club_user === 'user') {
        columnHeader = ['Verein', 'Aktivität', 'Stand', 'Standaufsicht', 'Waffe', 'Disziplin', 'Begin', 'Ende', 'bestätigt Verein'];
      } else if (this.club_user === 'club') {
        columnHeader = ['Name', 'Vorname', 'Stand', 'Standaufsicht', 'Waffe', 'Disziplin', 'Begin', 'Ende', 'bestätigt Verein'];
      }
      let pdfName = 'Schiessbuch von \n' + this.name;
      let pdfDateiName = 'Schiessbuch von ' + this.name;
      let pdfImage = '/Bilder/book.png';
      source.forEach(element => {

        var temp = [];
        if (this.club_user === 'club'){
          temp = [
            element.name,
            element.vorname,
          ];
        } else if (this.club_user === 'user'){
          temp = [
            element.comp_vereinname,
            element.aktivitaet,
          ];
        }

        temp.push(
          element.KurzBez,
          element.Aufsicht,
          element.KurzT,
          element.LangBez,
          new Date(element.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'}),
          new Date(element.ende.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'}),
        );
        if (element.bestaetigung_holen === 1) {
          temp.push(new Date(element.bestaetigt_vereinDatum).toLocaleString([], {
            dateStyle: 'short',
            timeStyle: 'short'
          }))
        } else {
          temp.push('')
        }
        rows.push(temp);
      });

      var doc = new jsPDF();
      // Header
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.text(pdfName, 10, 22);
      doc.setFontSize(12);
      doc.text("Erstellt am " + new Date().toLocaleString([], {dateStyle: 'short', timeStyle: 'short'}) + " von schuetzenbuch.de", 10, 40);
      doc.addImage(pdfImage, "PNG", 180, 15, 20, 20);


      doc.autoTable(columnHeader, rows, {

        startY: 50,
        margin: {horizontal: 10},
        styles: {overflow: "linebreak"},
        bodyStyles: {valign: "top"},
        columnStyles: {email: {cellWidth: "wrap"}},
        theme: "striped",
        showHead: "everyPage",
        didDrawPage: function (data) {


          // Footer
          var str = "Seite " + doc.internal.getNumberOfPages();

          doc.setFontSize(10);

          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        }
      });
      // console.log(doc.autoTable.previous);
      doc.save(pdfDateiName + '.pdf');

    },
  },
}
</script>

<style scoped>

</style>